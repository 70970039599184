import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_AGENCY_CODE_DIALOGUE } from "../../store/hostRequest/hostRequest.type";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  acceptHostRequest,
  acceptHostRequestWithAgecyCode,
  hostRequestAccept,
} from "../../store/hostRequest/hostRequest.action";
import { getAgency } from "../../store/Agency/agency.action";
import { Cancel } from "@mui/icons-material";

const AddAgencyCodeDialogue = () => {
  const dispatch = useDispatch();
  const { dialog1: open, dialogData1 } = useSelector(
    (state) => state.hostRequest
  );
  const { agency } = useSelector((state) => state.agency);

  const [agencyCode, setAgencyCode] = useState();
  const [addAgency, setAddAgency] = useState();
  const [errors, setErrors] = useState({
    agencyCode: "",
  });

  console.log("agencyCode", agencyCode);
  console.log("dialogData1", dialogData1?.data[0]?._id);

  useEffect(() => {
    dispatch(getAgency(1, 1000));
  }, [1, 1000]);

  useEffect(
    () => () => {
      setErrors({
        agencyCode: "",
      });
      setAgencyCode("");
    },
    [open]
  );

  const closePopup = () => {
    dispatch({ type: CLOSE_AGENCY_CODE_DIALOGUE });
  };

  console.log("dialogData1",dialogData1)

  const handleSubmit = () => {
    if (!agencyCode || agencyCode === "Select Code") {
      let error = {};
      if (!agencyCode) error.agencyCode = "agencyCode can't be a blank!";

      if (agencyCode === "Select Code")
        error.agencyCode = "agencyCode can't be a blank!";

      return setErrors({ ...error });
    } else {
      ;
      dispatch(
        acceptHostRequestWithAgecyCode(
          dialogData1?.data?._id,
          "accept",
          agencyCode
        )
      );
    }

    closePopup();
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          id="responsive-dialog-title"
          className="dialogue_background_color"
        >
          <span className="modal-title font-weight-bold h4">
            {" "}
            Select Agency Code{" "}
          </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="modal-title" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent className="dialogue_background_color">
          <div class="form-group col-12 my-3">
            <label className="mb-2 mt-2 text-gray">Select Agency Code</label>

            <select
              class="form-select form-control"
              aria-label="Default select example"
              value={agencyCode}
              onChange={(e) => {
                setAgencyCode(e.target.value);

                if (!e.target.value) {
                  return setErrors({
                    ...errors,
                    agencyCode: "Please select a AgencyCode!",
                  });
                } else if (e.target.value === "Select Code") {
                  return setErrors({
                    ...errors,
                    agencyCode: "Please select a AgencyCode!",
                  });
                } else {
                  return setErrors({
                    ...errors,
                    agencyCode: "",
                  });
                }
              }}
            >
              <option selected value="Select Code">
                Select Code
              </option>
              {Array.isArray(agency) &&
                agency.map((agencyItem) => {
                  return (
                    <option
                      key={agencyItem?.agencyCode}
                      value={agencyItem?.agencyCode}
                    >
                      {agencyItem?.agencyCode}
                    </option>
                  );
                })}
            </select>
            {errors.agencyCode && (
              <div className="ml-2 mt-1">
                {errors.agencyCode && (
                  <div className="pl-1 text__left">
                    <span className="text-danger">{errors.agencyCode}</span>
                  </div>
                )}
              </div>
            )}
          </div>
          <div
            className={" pt-3"}
            style={{
              display: "flex",
              justifyContent: "end",
              columnGap: "10px",
            }}
          >
            <button
              type="button"
              className="btn btn-outline-info ml-2 my-3 btn-round float__right icon_margin"
              onClick={closePopup}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-round float__right my-3 btn-danger"
              onClick={handleSubmit}
            >
              Accept
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddAgencyCodeDialogue;
