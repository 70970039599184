import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
//redux
import { connect, useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import girlImg from "../../assets/img/maleImage.png";

//MUI
// import {
//   Dialog,
//   DialogContent,
//   DialogTitle,
//   IconButton,
//   Tooltip,
// } from "@material-ui/core";
// import { Cancel } from "@material-ui/icons";

//action
import { createNewAgency, editAgency } from "../../store/Agency/agency.action";
import Male from "../../assets/img/maleImage.png";
import { CLOSE_AGENCY } from "../../store/Agency/agency.type";
import $ from "jquery";
import { getCoinSellerUniqueId } from "../../store/CoinSeller/CoinSeller.action";
import { Cancel } from "@mui/icons-material";
// import { getBd } from "../../store/bd/action";
// import { getCoinSellerUniqueId } from "../../store/coinSeller/action";

const AgencyDialogue = (props) => {
  const dispatch = useDispatch();

  //   const { bd } = useSelector((state) => state.bd);
  const { coinSellerId } = useSelector((state) => state.coinSeller);

  const {
    dialog: open,
    dialogData,
    agency,
  } = useSelector((state) => state.agency);

  const [imageData, setImageData] = useState([]);
  const [data, setData] = useState([]);
  const [uniqueId, setUniqueId] = useState("");
  const [search, setSearch] = useState("");
  const [imagePath, setImagePath] = useState(null);
  const [mongoId, setMongoId] = useState("");
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [code, setCode] = useState("");
  const [paymentDetails, setPaymentDetails] = useState("");
  const [paymentGateway, setPaymentGateway] = useState("");
  const [errors, setError] = useState({
    name: "",
    uniqueId: "",
    mobileNumber: "",
    code: "",
    image: "",
    paymentDetails,
    paymentGateway,
  });

  console.log("errors", errors);

  console.log("uniqueId", uniqueId);

  useEffect(() => {
    dispatch(getCoinSellerUniqueId(search));
    dispatch({ type: CLOSE_AGENCY });
  }, [1, 100, search]);

  useEffect(() => {
    setData(coinSellerId);
  }, [coinSellerId]);

  useEffect(() => {
    if (dialogData) {
      setMongoId(dialogData?._id);
      setName(dialogData?.name);
      setImagePath(dialogData?.image);
      setUniqueId(dialogData?.uniqueId);
      setMobileNumber(dialogData?.mobile);
      setCode(dialogData?.agencyCode);
      setPaymentDetails(dialogData?.paymentDetails);
      setPaymentGateway(dialogData?.paymentGateway);
    }
  }, [dialogData]);

  $(document).ready(function () {
    $("img").bind("error", function () {
      $(this).attr("src", Male);
    });
  });

  useEffect(
    () => () => {
      setError({
        name: "",
        mobileNumber: "",
        password: "",
        code: "",
        image: "",
        uniqueId: "",
        paymentDetails: "",
        paymentGateway: "",
      });
      setMongoId("");
      setName("");
      setUniqueId("");
      setCode("");
      setMobileNumber("");
      setImageData([]);
      setImagePath(null);
      setPaymentDetails("");
      setPaymentGateway("");
    },
    [open]
  );

  const handleInputImage = (e) => {
    if (e.target.files[0]) {
      setImageData(e.target.files[0]);
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        setImagePath(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
    if (!e.target.files[0]) {
      return setError({
        ...errors,
        image: "Please select an Image!",
      });
    } else {
      return setError({
        ...errors,
        image: "",
      });
    }
  };

  const createCode = () => {
    const randomChars = "0123456789";
    let code_ = "";
    for (let i = 0; i < 10; i++) {
      code_ += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
      setCode(code_);
    }
    if (!code_) {
      return setError({
        ...errors,
        code: "Code can't be a blank!",
      });
    } else {
      return setError({
        ...errors,
        code: "",
      });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = (e) => {
    if (dialogData) {
      if (!name) {
        const errors = {};
        if (!name) {
          errors.name = "Name can't be a blank!";
        }
        return setError({ ...errors });
      }
    } else {
      if (
        !name ||
        !code ||
        !mobileNumber ||
        !uniqueId ||
        !paymentDetails ||
        !paymentGateway
      ) {
        const errors = {};
        if (!name) {
          errors.name = "Name can't be a blank!";
        }
        if (!uniqueId) errors.uniqueId = "UniqueId Is Required !";
        if (!mobileNumber) errors.mobileNumber = "MobileNumber is Required !";
        if (!code) {
          errors.code = "Code can't be a blank!";
        }

        if (!paymentDetails)
          errors.paymentDetails = "Payment Details is Required !";

        if (!paymentGateway)
          errors.paymentGateway = "Payment Gateway is Required !";

        // if (!dialogData && !imagePath) {
        //   errors.imageData = "Please select an Image!";
        // }

        if (code?.length > 10) {
          return setError({
            ...errors,
            code: "Maximum 6 Digits are Allowed!",
          });
        }

        if (code?.length < 10) {
          return setError({
            ...errors,
            code: "Minimum 6 Digits are Allowed!",
          });
        }

        if (!mongoId) {
          const index = agency?.findIndex(
            (agency) => agency?.code?.toString() === code
          );
          if (index > -1) {
            return setError({ ...errors, code: "Code already exist." });
          }
        } else {
          const index = agency?.find(
            (agency) => agency?.code?.toString() === code
          );
          if (index !== undefined) {
            if (index?._id === mongoId) {
            } else {
              return setError({ ...errors, code: "Code already exist." });
            }
          }
        }
        return setError({ ...errors });
      }
    }

    const formData = new FormData();
    if (!mongoId) {
      formData.append("uniqueID", uniqueId);
      formData.append("agencyCode", code);
      formData.append("mobile", mobileNumber);
    }

    formData.append("image", imageData);
    formData.append("name", name);
    formData.append("paymentDetails", paymentDetails);
    formData.append("paymentGateway", paymentGateway);

    if (mongoId) {
      props.editAgency(formData, mongoId);
    } else {
      props.createNewAgency(formData);
    }
    closePopup();
  };

  const closePopup = () => {
    dispatch({ type: CLOSE_AGENCY });
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      ":hover": {
        backgroundColor: "#BBDEFB",
        color: "#000",
      },
    }),
  };

  const handleImage = (e) => {
    setImageData(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          id="responsive-dialog-title"
          className="dialogue_background_color"
        >
          <span className="modal-title font-weight-bold h4"> Agency </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="modal-title" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent className="dialogue_background_color">
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      {errors.bd && (
                        <div className="ml-2 mt-1">
                          {errors.bd && (
                            <div className="pl-1 text__left">
                              <span className="text-danger">{errors.bd}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {!dialogData && (
                    <div className="col-12 mt-3">
                      <div className="form-group">
                        <label className="mb-2 text-gray">
                          Unique Id of User
                        </label>

                        <Select
                          value={data.find(
                            (option) => option?.uniqueID === uniqueId
                          )}
                          styles={customStyles}
                          isSearchable={dialogData ? false : true}
                          disabled={dialogData ? true : false}
                          options={data}
                          getOptionLabel={(option) => option?.uniqueID} // Assuming uniqueId is the label for options
                          formatOptionLabel={(option) => (
                            <div className="country-option">
                              <img
                                src={option?.image ? option?.image : girlImg}
                                style={{
                                  height: "30px",
                                  width: "30px",
                                  borderRadius: "50%",
                                }}
                                alt="host"
                              />
                              <span className="ms-3">{option?.uniqueID}</span>
                            </div>
                          )}
                          onChange={(selectedOption) => {
                            setUniqueId(selectedOption?.uniqueID);
                            if (!selectedOption?.uniqueID) {
                              setError({
                                ...errors,
                                uniqueId: "Please select a uniqueId!",
                              });
                            } else {
                              setError({
                                ...errors,
                                uniqueId: "",
                              });
                            }
                          }}
                          onInputChange={(inputValue) => {
                            if (inputValue?.length > 3) {
                              setSearch(inputValue);
                            }
                          }}
                        />

                        {errors.uniqueId && (
                          <div className="ml-2 mt-1">
                            {errors.uniqueId && (
                              <div className="pl-1 text__left">
                                <span className="text-danger">
                                  {errors.uniqueId}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div class="form-group col-12 mt-3">
                    <label class="mb-2 text-gray">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Name"
                      required
                      value={name}
                      onKeyPress={handleKeyPress}
                      onChange={(e) => {
                        setName(e.target.value.trim());

                        if (!e.target.value) {
                          return setError({
                            ...errors,
                            name: "Name can't be a blank!",
                          });
                        } else {
                          return setError({
                            ...errors,
                            name: "",
                          });
                        }
                      }}
                    />
                    {errors.name && (
                      <div className="ml-2 mt-1">
                        {errors.name && (
                          <div className="pl-1 text__left">
                            <span className="text-danger">{errors.name}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {!mongoId && (
                  <div class={`${mongoId ? "col-12" : "col-md-12"} px-0`}>
                    <div class="form-group pl-3">
                      <label class="mb-2 text-gray">Mobile Number</label>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Enter Mobile Number"
                        required
                        value={mobileNumber}
                        onKeyPress={handleKeyPress}
                        onChange={(e) => {
                          setMobileNumber(e.target.value);

                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              mobileNumber: "mobileNumber can't be a blank!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              mobileNumber: "",
                            });
                          }
                        }}
                      />
                      {errors.mobileNumber && (
                        <div className="ml-2 mt-1">
                          {errors.mobileNumber && (
                            <div className="pl-1 text__left">
                              <span className="text-danger">
                                {errors.mobileNumber}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {!mongoId && (
                  <div class="row d-flex mt-3">
                    <div class={`${mongoId ? "col-12" : "col-md-9"}`}>
                      <div class="form-group">
                        <label class="mb-2 text-gray">Agency Code</label>
                        <input
                          readOnly
                          type="number"
                          class="form-control"
                          placeholder="Enter Code"
                          required
                          value={code}
                          onKeyPress={handleKeyPress}
                          onChange={(e) => {
                            setCode(e.target.value);

                            if (!e.target.value) {
                              return setError({
                                ...errors,
                                code: "Code can't be a blank!",
                              });
                            } else {
                              return setError({
                                ...errors,
                                code: "",
                              });
                            }
                          }}
                        />
                        {errors.code && (
                          <div className="ml-2 mt-1">
                            {errors.code && (
                              <div className="pl-1 text__left">
                                <span className="text-danger">
                                  {errors.code}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    {!mongoId && (
                      <div
                        class="col-md-3 pl-0 d-flex justify-content-end align-items-center"
                        style={{ marginTop: "22.01px" }}
                      >
                        <button
                          type="button"
                          class="btn btn-info"
                          style={{
                            borderRadius: 5,
                            fontSize: "14px",
                            padding: "8px",
                          }}
                          onClick={createCode}
                        >
                          Auto Generate
                        </button>
                      </div>
                    )}
                  </div>
                )}
                {mongoId && (
                  <div>
                    <div className="form-group mb-0">
                      <p className="form-label fw-bold mt-3">Image</p>
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      className="form-control "
                      autocomplete="off"
                      onChange={(e) => handleImage(e)}
                      onKeyPress={handleKeyPress}
                    />
                    {imagePath && (
                      <img
                        src={imagePath}
                        alt="withdraw"
                        draggable="false"
                        className="mt-3 "
                        style={{
                          height: "95px",
                          width: "130px",
                          borderRadius: "12px",
                          display: "block",
                        }}
                      />
                    )}
                    {errors.image && (
                      <div className="ml-1 mt-1">
                        {errors.image && (
                          <div className="pl-1 text__left">
                            <span className="text-danger">{errors.image}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                <div class="form-group col-12 mt-3 pl-0 ml-3">
                  <label class="mb-2 text-gray">PaymentDetails</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter PaymentDetails"
                    required
                    value={paymentDetails}
                    onKeyPress={handleKeyPress}
                    onChange={(e) => {
                      setPaymentDetails(e.target.value.trim());

                      if (!e.target.value) {
                        return setError({
                          ...errors,
                          paymentDetails: "Payment Detail is Required!",
                        });
                      } else {
                        return setError({
                          ...errors,
                          paymentDetails: "",
                        });
                      }
                    }}
                  />
                  {errors.paymentDetails && (
                    <div className="ml-2 mt-1">
                      {errors.paymentDetails && (
                        <div className="pl-1 text__left">
                          <span className="text-danger">
                            {errors.paymentDetails}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div class="form-group col-12 mt-3 pl-0 ml-3">
                  <label class="mb-2 text-gray">PaymentGateway</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter PaymentGateway"
                    required
                    value={paymentGateway}
                    onKeyPress={handleKeyPress}
                    onChange={(e) => {
                      setPaymentGateway(e.target.value.trim());

                      if (!e.target.value) {
                        return setError({
                          ...errors,
                          paymentGateway: "Payment Gateway is Required!",
                        });
                      } else {
                        return setError({
                          ...errors,
                          paymentGateway: "",
                        });
                      }
                    }}
                  />
                  {errors.paymentGateway && (
                    <div className="ml-2 mt-1">
                      {errors.paymentGateway && (
                        <div className="pl-1 text__left">
                          <span className="text-danger">
                            {errors.paymentGateway}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div
                  className={imagePath ? "mt-3 pt-3" : "mt-5"}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    columnGap: "10px",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(null, { createNewAgency, editAgency })(AgencyDialogue);
