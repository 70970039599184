import { apiInstanceFetch } from "../../util/api";
import { setToast } from "../../util/toast";
import {
  GET_AGENCY,
  CREATE_NEW_AGENCY,
  EDIT_AGENCY,
  CLOSE_AGENCY,
  SET_CREATE_AGENCY_DONE,
  SET_UPDATE_AGENCY_DONE,
  ENABLE_DISABLE_AGENCY,
  GET_AGENCY_DROPDOWN,
  GET_AGENCY_WISE_HOST,
  BLOCK_UNBLOCK_SWITCH_AGENCYUSERS,
  ACCEPT_DECLINE_AGENCY,
  GET_UNVERIFIED_AGENCY,
  REDEEM_ENDABLED_SWITCH_AGENCY,
  REDEEM_ENDABLED_SWITCH_HOST,
  GET_AGENCY_PROFILE,
  GET_AGENCY_BLOCK,
} from "./agency.type";

import axios from "axios";

export const getAgency = (start, limit) => (dispatch) => {
  apiInstanceFetch
    .get(`agency/index?start=${start}&limit=${limit}`)
    .then((res) => {
      dispatch({ type: GET_AGENCY, payload: res });
    })
    .catch((error) => console.log(error));
};

// get Agency profile
export const getAgencyProfile = (id) => (dispatch) => {
  apiInstanceFetch
    .get(`agency/getAgencyProfile?agencyId=${id}`)
    .then((res) => {
      dispatch({
        type: GET_AGENCY_PROFILE,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log(error);
      setToast("error", error.message);
    });
};

export const getUnVerifiedAgency = (start, limit) => (dispatch) => {
  apiInstanceFetch
    .get(`agency/unverifiedAgency`)
    .then((res) => {
      dispatch({ type: GET_UNVERIFIED_AGENCY, payload: res });
    })
    .catch((error) => console.log(error));
};

export const acceptAgency = (agencyId) => (dispatch) => {
  axios
    .patch(`agency/verifyAgency?agencyId=${agencyId}`)
    .then((res) => {
      dispatch({ type: ACCEPT_DECLINE_AGENCY, payload: agencyId });
    })
    .catch((error) => console.log(error));
};

export const deleteAgency = (agencyId) => (dispatch) => {
  axios
    .delete(`agency/declineAgency?agencyId=${agencyId}`)
    .then((res) => {
      dispatch({ type: ACCEPT_DECLINE_AGENCY, payload: agencyId });
    })
    .catch((error) => console.log(error));
};

export const getAgencyWiseHost = (id, start, limit, search) => (dispatch) => {
  apiInstanceFetch
    .get(
      `agency/agencyWiseHost?agencyId=${id}&startDate=${"ALL"}&endDate=${"ALL"}`
    )
    .then((res) => {
      dispatch({
        type: GET_AGENCY_WISE_HOST,
        payload: { data: res?.data, total: res?.total },
      });
    })
    .catch((error) => console.log(error));
};

export const handleBlockUnblockSwitch = (host) => (dispatch) => {
  axios
    .patch(`host/isBlock?hostId=${host?._id}`)
    .then((res) => {
      console.log("res", res?.data?.host);
      if (res.data.status) {
        dispatch({
          type: BLOCK_UNBLOCK_SWITCH_AGENCYUSERS,
          payload: res.data.host,
        });
        setToast(
          "success",
          ` Host  ${
            host.isBlock !== true ? "Blocked" : "Unblocked"
          } Successfully!`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};
export const redeemEnableAgency = (userId) => (dispatch) => {
  axios
    .patch(`agencyRedeem/handleRedeem?agencyId=${userId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: REDEEM_ENDABLED_SWITCH_AGENCY,
          payload: res.data.data,
        });
        setToast(
          "success",
          `${
            res.data?.data?.redeemEnable === true
              ? "Enabled Successfully"
              : "Disabled Successfully"
          }`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};

export const getAgencyDropdown = () => (dispatch) => {
  apiInstanceFetch
    .get("agency/agencyDropDown")
    .then((res) => {
      if (res.status) {
        dispatch({ type: GET_AGENCY_DROPDOWN, payload: res.data });
      }
    })
    .catch((error) => console.log(error));
};

export const createNewAgency = (formData) => (dispatch) => {
  axios
    .post("agency/store", formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: CREATE_NEW_AGENCY, payload: res.data.data });
        dispatch({ type: CLOSE_AGENCY });
        dispatch({ type: SET_CREATE_AGENCY_DONE });
        setToast("success", "Agency Create Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
export const editAgency = (formData, id) => (dispatch) => {
  axios
    .patch(`agency/update?agencyId=${id}`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: EDIT_AGENCY,
          payload: { data: res.data.data, id },
        });
        dispatch({ type: CLOSE_AGENCY });
        dispatch({ type: SET_UPDATE_AGENCY_DONE });
        setToast("success", "Agency Update Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

export const enableDisableAgency = (id) => (dispatch) => {
  axios
    .patch(`agency/activeOrNot?agencyId=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: ENABLE_DISABLE_AGENCY, payload: res.data.data });
        setToast(
          "success",
          `${
            res.data?.data?.isActive === true
              ? "Disable Successfully"
              : "Enable Successfully"
          }`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
export const redeemEnableHost = (id) => (dispatch) => {
  axios
    .put(`redeem/handleRedeem?userId=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: REDEEM_ENDABLED_SWITCH_HOST, payload: res.data.data });
        setToast(
          "success",
          `${
            res.data?.data?.redeemEnable === true
              ? "Enabled Successfully"
              : "Disabled Successfully"
          }`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

export const blockAgency = (user) => (dispatch) => {
  axios
    .patch(`agency/blockAgency?agencyId=${user?._id}`)
    .then((res) => {
      dispatch({
        type: GET_AGENCY_BLOCK,
        payload: { data: res.data.agency, id: user._id },
      });
      setToast(
        "success",
        `${user.name} Is ${
          user.isBlock !== true ? "Blocked" : "Unblocked"
        } Successfully!`
      );
    })
    .catch((error) => {
      console.log(error);
      setToast("error", error.message);
    });
};
